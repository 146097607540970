import React from "react";
import {List, Popconfirm} from "antd";
import {Link, withRouter} from "react-router-dom";
import CpListHeader from "../common/list/CpListHeader";
import {CpIconAdd, CpIconPhone, CpIconUser} from "../common/CpIcon";
import FormManager from "../common/forms/FormManager";
import {DataTypeEnum} from "../common/enums/DataTypeEnum";
import CpTitle from "../common/CpTitle";
import CpCoproDeadline from "../copro/CpCoproDeadline";
import CpHubspotButton from "../common/buttons/CpHubspotButton";
import FormCommercial from "../common/forms/FormCommercial";
import variables from "../../stylesheets/tools/variables";
import CpButton from "../common/buttons/CpButton";
import Documents from "../document/Documents";
import styled from "styled-components";
import DataService from "../../services/dataService";
import endpointEnum from "../../services/endpointEnum";
import CpModalTransaction from "../common/modal/CpModalTransaction";
import {PlusSquareOutlined} from "@ant-design/icons";

const StyledCoproEchues = styled.div`
  font-size: 85%;
  font-style: italic;
  font-weight: 550;
  width: 100%;
  color: #8c8c8c;
`;

const GestionnaireList = (props) => {
  const {compteurs} = props;
  const {match} = props;
  const {item} = props.detail;
  const {isAdmin} = props.user;

  const removeCommercial = (agencyId, commercialId) => {
    DataService.deleteCommercial(agencyId, commercialId).then(
      () => props.detail.load(agencyId, "list"),
    );
  }

  return (<>
    {/* Managers */}
    <List
      header={
        <CpListHeader
          visible={match.params.create ? true : false}
          icon={<CpIconUser/>}
          title="Gestionnaire"
          count={item?.managers ? item?.managers?.length : 0}
          form={FormManager}
          customCheck={(formValues) => {
            const donneesFormAssistant = Object.values(
              formValues.getFieldsValue([
                "assistantEmail",
                "assistantFirstname",
                "assistantLastname",
                "assistantMobile",
                "assistantPhone"]));
            const donneesAssistantRequises = Object.values(
              formValues.getFieldsValue([
                "assistantFirstname",
                "assistantLastname",
                "assistantEmail"]));
            /** Some assistant form values are compulsory ONLY if the user has fill some fields to create a new assistant. **/
            if (donneesFormAssistant.filter(
                valeur => valeur !== "" && valeur !== undefined).length > 0 &&
              donneesAssistantRequises.some(
                donneeRequise => donneeRequise === "" || donneeRequise ===
                  undefined)) {
              return "Si vous voulez ajouter un·e assistant·e, merci de renseigner nom, prénom et email.";
            } else {
              return null;
            }
          }}
          values={
            {agency: item.id}
          }
          dataType={DataTypeEnum.MANAGER}
          onSubmit={(values, redirect) => redirect ?
            props.history.push(`/manager/${values.id}/copros/create`) :
            props.detail.load(item.id, "list")}
          onSubmitRedirect={"Ajouter une Copropriété"}
          filter={
            <div style={{paddingBottom: "12px"}}>
              <CpModalTransaction
                item={{compteurs: compteurs}}
                buttonProps={{
                  icon: <CpIconAdd/>,
                  cpsize: "large",
                  type: "secondary",
                  className: "ant-btn show-btn",
                  block: true,
                  style: {width: "110px", minWidth: "110px", float: "right"},
                }}
                manager={item.id}
                name={item.name}
                size={"lg"}>
                <PlusSquareOutlined/>&nbps;Transaction
              </CpModalTransaction>
            </div>
          }
        />
      }
      bordered={false}
      dataSource={item?.managers ?? []}
      renderItem={manager => {
        return (
          <Link to={`/manager/${manager.id}/copros`}>
            <List.Item style={{display: "flex"}}>
              <List.Item.Meta
                style={{flexGrow: "9"}}
                title={
                  <>
                    <CpTitle level={4}>{`${manager.prénom} ${manager.nom}`}</CpTitle>
                    {manager.estDirecteur ?
                      <CpTitle level={5}>Directeur</CpTitle> :
                      null}
                  </>
                }
                description={<>{manager.email}</>}
              />
              <List.Item.Meta
                style={{flexGrow: "5"}}
                description={
                  (manager.fixe || manager.mobile) &&
                  <div>
                    <CpIconPhone size={30} style={{
                      float: "left",
                      marginRight: 10,
                      padding: 7,
                    }}/>
                    <div style={{display: "inline-block"}}>
                      {manager.fixe ? <div
                        style={{
                          lineHeight: manager.mobile ?
                            "22px" :
                            "44px",
                        }}> {manager.fixe}</div> : null}
                      {manager.mobile ? <div
                        style={{
                          lineHeight: manager.phone ?
                            "22px" :
                            "44px",
                        }}> {manager.mobile}</div> : null}
                    </div>
                  </div>
                }
              />
              <List.Item.Meta
                style={{flexGrow: "3"}}
                title={<>Status</>}
                description={
                  <>
                    {manager.estActif ? "Actif" : "Inactif "}
                  </>
                }
              />
              <List.Item.Meta
                style={{flexGrow: "6"}}
                title={<>Nb copros</>}
                description={
                  <div style={{position: "relative"}}>
                    <CpCoproDeadline
                      total={manager.totalCopropriétés}
                      deadlines={manager.echeancesCopros ?? {}}
                    />
                    {manager.nbCoprosEchues < 1 ?
                      "" :
                      <StyledCoproEchues>{manager.nbCoprosEchues} Copro{manager.nbCoprosEchues >
                      1 ? "s" : ""} échue{manager.nbCoprosEchues > 1 ?
                        "s" :
                        ""}</StyledCoproEchues>}
                  </div>
                }
              />
              <List.Item.Meta
                style={{flexGrow: "1"}}
                description={<CpHubspotButton item={manager} type={"contact"}
                                              title={"contact dans hubspot"}/>}
              />
            </List.Item>
          </Link>
        );
      }}
    />

    <List
      header={
        <CpListHeader
          icon={<CpIconUser color={"red"}/>}
          title="Commercial"
          plural="Commerciaux"
          count={item?.commercials ? item?.commercials?.length : 0}
          form={FormCommercial}
          values={
            {agencyId: item.id}
          }
          endPoint={endpointEnum.COMMERCIAL_AJOUT(item.id)}
          onSubmit={() => props.detail.load(item.id, "list")}
        />
      }
      bordered={false}
      dataSource={item.commercials}
      renderItem={commercial => (
        <List.Item style={{display: "flex"}}>
          <List.Item.Meta
            style={{flexGrow: "10"}}
            title={<CpTitle
              level={4}>{commercial.qualifiedName}</CpTitle>}
            description={<>{commercial.email}</>}
          />
          <List.Item.Meta
            style={{flexGrow: "10"}}
            description={
              <div style={{lineHeight: "30px"}}>
                <CpIconPhone
                  size={30}
                  style={{
                    float: "left",
                    marginRight: 10,
                    padding: 7,
                  }}/>
                {commercial?.fixe ?? commercial?.mobile}
              </div>
            }
          />
          <List.Item.Meta
            style={{
              flexGrow: "2",
              paddingLeft: "20px",
              borderLeft: `2px solid ${variables.grey4}`,
            }}
            title={""}
            description={
              (
                (isAdmin && item.commercials.length > 1) ?
                  <Popconfirm
                    title={"Vous souhaitez vraiment désassocier ce commercial ?"}
                    okText={"Oui"} cancelText={"Non"}
                    onConfirm={() => removeCommercial(item.id,
                      commercial.id)}>
                    <CpButton>Désassocier</CpButton>
                  </Popconfirm>
                  : null
              )

            }
          />
        </List.Item>
      )}
    />

  {/* Documents */}
    <Documents key={item.id} agency={item}
               dataSource={item.documents ?? []}/>
  </>);
};
GestionnaireList.defaultProps = {};

export default withRouter(GestionnaireList);
